@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('./assets/fonts/source-sans-pro/source-sans-pro-regular.woff2')
      format('woff2'),
    url('./assets/fonts/source-sans-pro/source-sans-pro-regular.woff')
      format('woff'),
    url('./assets/fonts/source-sans-pro/source-sans-pro-regular.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: local(''),
    url('./assets/fonts/source-sans-pro/source-sans-pro-600.woff2')
      format('woff2'),
    url('./assets/fonts/source-sans-pro/source-sans-pro-600.woff')
      format('woff'),
    url('./assets/fonts/source-sans-pro/source-sans-pro-600.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: local(''),
    url('./assets/fonts/source-sans-pro/source-sans-pro-300.woff2')
      format('woff2'),
    url('./assets/fonts/source-sans-pro/source-sans-pro-300.woff')
      format('woff'),
    url('./assets/fonts/source-sans-pro/source-sans-pro-300.ttf')
      format('truetype');
}

body {
  @apply font-primary;
}

.w-c {
  will-change: opacity;
}

input,
textarea,
button,
select,
div,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px #4e4e4e;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #4e4e4e;
}
